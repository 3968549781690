import * as React from "react"
import Footer from "../components/footer"
import Header from "../components/header"
import Services from "../components/contact"

// markup
const ServicesPage = () => {
    return (
        <main>
            <Header />
            <Services />
            <Footer />
        </main>
    )
}

export default ServicesPage
